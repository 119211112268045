.main-tabs {
  width: 100%;
  justify-content: center;
  display: flex;
}
.tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}
.tabs label {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  display: block;
  padding: 1rem 2rem;
  margin-right: 0.2rem;
  cursor: pointer;
  background: #5cb3ff;
  color: #fff;
  -webkit-transition: background ease 0.2s;
  -webkit-transition: background ease 0.2s;
  transition: background ease 0.2s;
}
.tabs .tab {
  -webkit-box-ordinal-group: 100;
  -webkit-order: 99;
  -ms-flex-order: 99;
  order: 99;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  display: none;
  padding: 1rem;
  background: #fafafa;
}

.tabs input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.tabs input[type="radio"]:checked + label {
  background: #fafafa;
  color: #000;
}
.tabs input[type="radio"]:checked + label + .tab {
  display: block;
}
@media (max-width: 45em) {
  .tabs .tab,
  .tabs label {
    -webkit-box-ordinal-group: NaN;
    -webkit-order: initial;
    -ms-flex-order: initial;
    order: initial;
  }

  .tabs label {
    width: 100%;
    margin-right: 0;
    margin-top: 0.2rem;
  }
}
.tabs p {
  color: #000;
}
