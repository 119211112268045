.inpts {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}
.inp {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 10px;
}
@media (max-width: 768px) {
    .inp {

        width: 95%;
    
      }
}
.inp input {
  padding: 13px;
  border: 1px solid #ccc;
}
.inp input:focus {
  outline: #ddd;
}
.Terminanfrage {
  color: #54595f;
  margin-top: 132px;
}
.Terminanfrage .btnSubmit {
  display: block;
  margin: 10px auto;
  width: 70%;
  padding: 10px;
  background-color: #000;
  color: #fff;
  font-weight: bold;
}
