/*Start Footer*/
.footer {
  padding: 70px 0;
  background-color: #5cb3ff;
  color: aliceblue;
}
.footer h1,
.footer h2 {
  color: aliceblue;
}
.footer ul {
  list-style: none;
  padding: 0;
}
.footer ul li a {
  color: aliceblue !important;
  text-decoration: none;
}
.footer ul li {
  margin-bottom: 15px;
}
.footer i {
  font-size: 25px;
  color: white;
  margin-right: 15px;
  transition: all 0.5s ease;
  cursor: pointer;
}
.footer ul li i {
  color: #fff;
  font-size: 18px;
  margin: 0px 7px;
}
.footer i:hover {
  color: cyan;
}
.btn-main {
  background-color: #5cb3ff;
  padding: 10px;
  position: fixed;
  border-radius: 19px;
  z-index: 99999;
  right: 30px;
  bottom: 87px;
  text-decoration: none;
  color: white;
}
