body *:not(i) {
  margin: 0;
  font-family: "Almarai", sans-serif;
}
.heightsection {
  min-height: 70vh;
}
body {
  background-color: #d3d3d3;
}
#root {
  background-color: #d3d3d3;
}
