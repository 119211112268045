#contact {
  background: url("../../img/How to Start a Career as a Commercial Cleaner (5).webp")
    no-repeat;
  background-size: cover;
  color: #fff;
}
#contact h2 {
  padding-bottom: 10px;
}
#contact address {
  padding-top: 20px;
}
#contact address .fa {
  background: #28a7e9;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 6px;
  margin-right: 10px;
}
#contact .contact-form {
  padding-top: 40px;
}
#contact .form-control {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  margin-bottom: 20px;
}
#contact input {
  height: 50px;
}
#contact .buttcontact {
  background: #28a7e9;
  color: #fff;
  width: 164px;
  font-weight: bold;
  transition: all 0.3s ease;
  padding: 7px;
  border: none;
}
#contact .buttcontact:hover {
  background: #107db6;
}
#contact .overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  padding-top: 90px;
  padding-bottom: 90px;
}
.Toastify__toast-theme--light {
  font-family: "Almarai", sans-serif !important;
}
