.modal-dialog {
  max-width: 86% !important;
}
.modal-dialog {
  width: fit-content !important;
}
.modal-body {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}
.imgmodal {
  width: 194px;
}

.imgmodal img {
  width: 100%;
}
.textmodal {
  width: 68%;
}
