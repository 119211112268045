.SomeLink {
  padding: 48px;
  background-color: #d3d3d3;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
  margin-top: 132px;
}
.SomeLink .SomeConent > div {
  display: flex;
  height: 160px;
  width: 160px;
  padding: 15px;
  /* border-radius: 14px; */
  /* box-shadow: 0 16px 25px rgba(0, 0, 0, 0.25), 0 11px 11px rgba(0, 0, 0, 0.25); */
  flex-direction: column;
  text-align: center;
  margin: 15px;
  border: 1px solid #333;
  justify-content: center;
}
.SomeLink .SomeConent i {
  font-size: 80px;
  color: #888888;
}
.SomeLink h2 {
  text-align: center;
  margin: 12px auto;
  width: 100%;
  color: rgb(7, 28, 67);
}
