.mainvideo .overlay {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
}
.mainvideo {
  height: 400px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.mainvideo .vid {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.mainvideo h1,
.mainvideo h4 {
  position: absolute;
}
.mainvideo h4 {
  font-weight: bold;
  left: 50%;
  color: rgb(63, 177, 129);
  transform: translateX(-50%);
  z-index: 5;
  top: 100px;
}
.mainvideo h1 {
  font-weight: bold;
  left: 50%;
  top: 120px;
  color: rgb(63, 177, 129);
  transform: translateX(-50%);
  z-index: 5;
  width: 80%;
}
