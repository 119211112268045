.service {
  padding: 40px;
  background-color: #d3d3d3;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: 2px solid #5cb3ff;
}
.service .cardd {
  display: flex;
  height: 446px;
  width: 300px;
  padding: 15px;
  border-radius: 14px;
  flex-direction: column;
  text-align: center;
  margin: 15px;
  justify-content: space-around;
  font-size: 16px;
}
.service .cardd h6 {
  color: #000;
  font-weight: bold;
}
.service .cardd img {
  width: 80px;
  border-radius: 13px;
  margin: 0 auto;
  margin-bottom: 16px;
  object-fit: cover;
}
