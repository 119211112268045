.ss img {
  width: 100%;
  height: 87vh;
  object-fit: cover;
}
.ss {
  margin-top: 132px;
  position: relative;
}
.ss .slider-caption {
  position: absolute;
  z-index: 2;
  color: #fff;
  left: 144px;
  bottom: 150px;
}
@media (max-width: 768px) {
  .ss .slider-caption {
    position: absolute;
    z-index: 2;
    color: #fff;
    left: 10px;
    bottom: 297px;
  }
}
.ss .overlay {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
}
.ss button {
  padding: 8px;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}
