.info {
  padding-top: 100px;
  align-items: center;
  background-color: #d3d3d3;
  overflow: hidden;
  height: 100%;
  display: flex;
  border-bottom: 2px solid #5cb3ff;
  margin-top: 132px;
}

.info h2 {
  padding-bottom: 26px;
  color: #071c43;
  font-size: 44px;
  font-weight: bold;
}
.info p {
  color: black;
  font-size: 21px;
  margin-bottom: 20px;
}
.info .btn {
  border-radius: 0px;
  font-weight: bold;
  margin-top: 20px;
  background-color: #b59e6c;
  padding: 10px 40px 10px 40px;
}
.info img {
  width: 70%;
  margin: 0 auto;
  display: block;
}
